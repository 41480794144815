import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { IconButton } from '@entur/button';
import { Props } from 'docz';
import { Table, TableHead, TableBody, TableFooter, TableRow, HeaderCell, DataCell } from '@entur/table';
import { AddIcon, VerticalDotsIcon, MenuIcon, EditIcon, CloseIcon } from '@entur/icons';
import { Tooltip } from '@entur/tooltip';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'<Tooltip aria-hidden placement=\"bottom\" content=\"Legg til\">\n  <IconButton aria-label=\"Legg til\">\n    <AddIcon />\n  </IconButton>\n</Tooltip>\n<Tooltip aria-hidden placement=\"bottom\" content=\"Meny\">\n  <IconButton aria-label=\"Meny\">\n    <MenuIcon />\n  </IconButton>\n</Tooltip>\n<Tooltip aria-hidden placement=\"bottom\" content=\"Rediger\">\n  <IconButton aria-label=\"Rediger\">\n    <EditIcon />\n  </IconButton>\n</Tooltip>\n<Tooltip aria-hidden placement=\"bottom\" content=\"Lukk\">\n  <IconButton aria-label=\"Lukk\">\n    <CloseIcon />\n  </IconButton>\n</Tooltip>'} __scope={{
      props,
      DefaultLayout,
      IconButton,
      Props,
      Table,
      TableHead,
      TableBody,
      TableFooter,
      TableRow,
      HeaderCell,
      DataCell,
      AddIcon,
      VerticalDotsIcon,
      MenuIcon,
      EditIcon,
      CloseIcon,
      Tooltip,
      Playground,
      PageHeader,
      ImportStatement,
      DefaultLayout,
      _frontmatter
    }} style={{
      display: 'flex',
      flexDirection: 'row',
      gap: '2rem',
      paddingBottom: '2rem'
    }} mdxType="Playground">
  <Tooltip aria-hidden placement="bottom" content="Legg til" mdxType="Tooltip">
    <IconButton aria-label="Legg til" mdxType="IconButton">
      <AddIcon mdxType="AddIcon" />
    </IconButton>
  </Tooltip>
  <Tooltip aria-hidden placement="bottom" content="Meny" mdxType="Tooltip">
    <IconButton aria-label="Meny" mdxType="IconButton">
      <MenuIcon mdxType="MenuIcon" />
    </IconButton>
  </Tooltip>
  <Tooltip aria-hidden placement="bottom" content="Rediger" mdxType="Tooltip">
    <IconButton aria-label="Rediger" mdxType="IconButton">
      <EditIcon mdxType="EditIcon" />
    </IconButton>
  </Tooltip>
  <Tooltip aria-hidden placement="bottom" content="Lukk" mdxType="Tooltip">
    <IconButton aria-label="Lukk" mdxType="IconButton">
      <CloseIcon mdxType="CloseIcon" />
    </IconButton>
  </Tooltip>
    </Playground>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "iconbutton"
    }}>{`IconButton`}</h3>
    <ImportStatement imports="IconButton" mdxType="ImportStatement" />
    <Props of={IconButton} mdxType="Props" />
    <h2 {...{
      "id": "retningslinjer-for-bruk"
    }}>{`Retningslinjer for bruk`}</h2>
    <p>{`Siden ikonknapper bare et ikon er et `}<a parentName="p" {...{
        "href": "https://design.entur.no/komponenter/feedback/tooltip"
      }}>{`Tooltip`}</a>{` nødvendig når de brukes uten en label.
Ved å hovre eller fokusere på ikonknappen vil det da dukke opp et tooltip med en tekstbeskrivelse som forklarer
handlingen brukeren kan utføre.`}</p>
    <p>{`Siden de ikke har en label sparer disse knappene mye plass i et grensesnitt.
Ikonknapper lar deg ha flere ikonknapper ved siden av hverandre på et liten plassområde.
Tommelfingerregelen for ikonknapper er å kun bruke dem mde ikoner som man anser som globalt forståelige.`}</p>
    <h3 {...{
      "id": "hvordan-oppnå-god-uu"
    }}>{`Hvordan oppnå god UU`}</h3>
    <p>{`Det er viktig at knappen er tilgjenglig og forstålig for alle, for å sikre dette må du bruke en `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{` på knappen som beskriver hva den gjør. For å
unngå dobbel-opplesing i skjermleseren når du bruker Tooltip er det anbefalt å gjøre som i eksempelet før og sette en `}<inlineCode parentName="p">{`aria-hidden`}</inlineCode>{` på selve Tooltip-en.`}</p>
    <p>{`En annen løsning, som vil være enda mer tilgjengelig, er å ha en label til knappen – pass da på at label-en er koblet korrekt til knappen eller skjult med aria-hidden for å unngå dobbel-opplesing.
Knappen har per i dag ingen prop for label, si fra på `}<a parentName="p" {...{
        "href": "https://entur.slack.com/archives/C899QSPB7"
      }}>{`#talk-designsystem`}</a>{` hvis dette ønskes.`}</p>
    <h2 {...{
      "id": "eksempler"
    }}>{`Eksempler`}</h2>
    <p>{`Ikonknapper brukes ofte som inline-handlinger i tabellrader og verktøylinjer.`}</p>
    <Playground __position={3} __code={'<Table>\n  <TableHead>\n    <TableRow>\n      <HeaderCell>Fra</HeaderCell>\n      <HeaderCell>Til</HeaderCell>\n      <HeaderCell>Avgang</HeaderCell>\n      <HeaderCell>Rediger</HeaderCell>\n    </TableRow>\n  </TableHead>\n  <TableBody>\n    <TableRow>\n      <DataCell>Majorstua</DataCell>\n      <DataCell>Østerås</DataCell>\n      <DataCell>10.00</DataCell>\n      <DataCell>\n        <Tooltip aria-hidden placement=\"bottom\" content=\"Rediger reise\">\n          <IconButton aria-label=\"Rediger reise\">\n            <EditIcon />\n          </IconButton>\n        </Tooltip>\n      </DataCell>\n    </TableRow>\n    <TableRow>\n      <DataCell>Majorstua</DataCell>\n      <DataCell>Østerås</DataCell>\n      <DataCell>11.00</DataCell>\n      <DataCell>\n        <Tooltip aria-hidden placement=\"bottom\" content=\"Rediger reise\">\n          <IconButton aria-label=\"Rediger reise\">\n            <EditIcon />\n          </IconButton>\n        </Tooltip>\n      </DataCell>\n    </TableRow>\n    <TableRow>\n      <DataCell>Majorstua</DataCell>\n      <DataCell>Østerås</DataCell>\n      <DataCell>12.00</DataCell>\n      <DataCell>\n        <Tooltip aria-hidden placement=\"bottom\" content=\"Rediger reise\">\n          <IconButton aira-label=\"Rediger reise\">\n            <EditIcon />\n          </IconButton>\n        </Tooltip>\n      </DataCell>\n    </TableRow>\n  </TableBody>\n</Table>'} __scope={{
      props,
      DefaultLayout,
      IconButton,
      Props,
      Table,
      TableHead,
      TableBody,
      TableFooter,
      TableRow,
      HeaderCell,
      DataCell,
      AddIcon,
      VerticalDotsIcon,
      MenuIcon,
      EditIcon,
      CloseIcon,
      Tooltip,
      Playground,
      PageHeader,
      ImportStatement,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Table mdxType="Table">
    <TableHead mdxType="TableHead">
      <TableRow mdxType="TableRow">
        <HeaderCell mdxType="HeaderCell">Fra</HeaderCell>
        <HeaderCell mdxType="HeaderCell">Til</HeaderCell>
        <HeaderCell mdxType="HeaderCell">Avgang</HeaderCell>
        <HeaderCell mdxType="HeaderCell">Rediger</HeaderCell>
      </TableRow>
    </TableHead>
    <TableBody mdxType="TableBody">
      <TableRow mdxType="TableRow">
        <DataCell mdxType="DataCell">Majorstua</DataCell>
        <DataCell mdxType="DataCell">Østerås</DataCell>
        <DataCell mdxType="DataCell">10.00</DataCell>
        <DataCell mdxType="DataCell">
          <Tooltip aria-hidden placement="bottom" content="Rediger reise" mdxType="Tooltip">
            <IconButton aria-label="Rediger reise" mdxType="IconButton">
              <EditIcon mdxType="EditIcon" />
            </IconButton>
          </Tooltip>
        </DataCell>
      </TableRow>
      <TableRow mdxType="TableRow">
        <DataCell mdxType="DataCell">Majorstua</DataCell>
        <DataCell mdxType="DataCell">Østerås</DataCell>
        <DataCell mdxType="DataCell">11.00</DataCell>
        <DataCell mdxType="DataCell">
          <Tooltip aria-hidden placement="bottom" content="Rediger reise" mdxType="Tooltip">
            <IconButton aria-label="Rediger reise" mdxType="IconButton">
              <EditIcon mdxType="EditIcon" />
            </IconButton>
          </Tooltip>
        </DataCell>
      </TableRow>
      <TableRow mdxType="TableRow">
        <DataCell mdxType="DataCell">Majorstua</DataCell>
        <DataCell mdxType="DataCell">Østerås</DataCell>
        <DataCell mdxType="DataCell">12.00</DataCell>
        <DataCell mdxType="DataCell">
          <Tooltip aria-hidden placement="bottom" content="Rediger reise" mdxType="Tooltip">
            <IconButton airaLabel="Rediger reise" mdxType="IconButton">
              <EditIcon mdxType="EditIcon" />
            </IconButton>
          </Tooltip>
        </DataCell>
      </TableRow>
    </TableBody>
  </Table>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      